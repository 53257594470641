/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */

import React, { useState, useRef } from 'react';
import {
    string, func, shape, arrayOf, number, object,
} from 'prop-types';

import { makeStyles } from '@material-ui/core';
import mbpLogger from 'mbp-logger';
import { useUIDSeed } from 'react-uid';

// tracking
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getIsBot, getSSRDeviceType } from '../../../../../state/ducks/App/App-Selectors';
import { trackEvent as track } from '../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';

// helpers
import { GRAPHQL_ENV } from '../../../../gql';

// partials/components
import DropdownContent from './Partials/DropdownContent';
import NavbarContent from './Partials/NavbarContent';

const useStyles = makeStyles(() => ({
    dropdown: ({ styling }) => ({
        position: 'absolute',
        width: '100vw',
        height: 'fit-content',
        backgroundColor: styling?.colors?.bg_nav?.color || '#ffffff',
        zIndex: 1000,
        '&.show': {
            boxShadow: '0 4px 5px 0 rgb(0 0 0 / 40%)',
        },
    }),
}));

const NavigationMenu_V2 = ({
    data, brand, ariaLabel, trackEvent, keeperData,
}) => {
    if (!data?.menu_ref?.[0].menu_group?.length) {
        mbpLogger.logWarning({
            appName: process.env.npm_package_name,
            component: 'NavigationMenu_V2.js',
            message: `No usable data passed to component\n brand data = ${brand.domian}`,
            env: GRAPHQL_ENV,
        });
        return <></>;
    }

    const menuData = data.menu_ref[0];
    const menuGroup = menuData.menu_group || [];

    const ssrDeviceType = useSelector(getSSRDeviceType);
    const isBot  = useSelector(getIsBot);

    const hoverTimer = useRef(null);

    const seed = useUIDSeed();

    // States
    const [isDropDownShown, setIsDropDownShown] = useState(false);
    const [tabletHover, setTabletHover] = useState();
    const [menuOpen, setMenuOpen] = useState();

    // Styling
    const styling = menuData.styling;
    const classes = useStyles({
        styling, isDropDownShown, ssrDeviceType,
    });

    const hasDropdownContent = (tab) => (!!(tab?.link_group?.length || tab?.promoted_spots?.length)); // don't render a dropdown if there is no content for it

    const closeDropdown = () => {
        setMenuOpen(-1);
        setIsDropDownShown(false);
        setTabletHover(null);
    };

    const applyTabletHover = (item) => {
        setIsDropDownShown(true);
        setTabletHover(null);
        setTabletHover(`MainLink_${item}`);
    };

    // Just an effect to ease the drop/box shadow in
    const startInitialHover = () => { setIsDropDownShown(true); };

    const stopHover = () => {
        if (hoverTimer.current === -1) return;
        if (hoverTimer.current) clearTimeout(hoverTimer.current);
        hoverTimer.current = -1;
    };

    const startHover = (tab, item) => {
        if (isDropDownShown) setMenuOpen(item);
        if (!hasDropdownContent(tab)) {
            // If there is no dropdown content, stop all processes related to a dropdown
            closeDropdown();
            return;
        }
        // if there is already a hover occuring do not process the rest of this
        if (hoverTimer.current?.length === -1) return;

        // update the state to trigger dropdown
        const hoverTimerId = setTimeout(() => {
            setMenuOpen(item);
            setIsDropDownShown(true);
            hoverTimer.current = -1;
        }, 200);

        hoverTimer.current = hoverTimerId;
    };

    return (
        <div className="mainNavigation" onMouseLeave={closeDropdown} onMouseEnter={startInitialHover}>
            <NavbarContent
                menuGroup={menuGroup}
                tabletHover={tabletHover}
                stopHover={stopHover}
                closeDropdown={closeDropdown}
                applyTabletHover={applyTabletHover}
                startHover={startHover}
                ariaLabel={ariaLabel}
                trackEvent={trackEvent}
                menuOpen={menuOpen}
                styling={styling}
                keeperData={keeperData}
            />
            {(isDropDownShown || isBot) ? (
                <div className={`${classes.dropdown} ${isDropDownShown ? 'show' : ''}`} data-testid="dropdown">
                    {menuGroup?.map((tab, item) => (
                        // if the top-level "button" is not linked (no department lander) but has dropdown
                        hasDropdownContent(tab) && (
                            <DropdownContent
                                groupTitle={tab?.group_title}
                                closeDropdown={closeDropdown}
                                isDropDownShown={isDropDownShown}
                                styling={styling}
                                linkgroup={tab?.link_group}
                                promospots={tab?.promoted_spots}
                                menuIndex={item}
                                menuOpen={menuOpen}
                                key={`dropdown_${seed(item)}`}
                            />
                        )
                    ))}
                </div>
            ) : null}
        </div>
    );
};

NavigationMenu_V2.propTypes = {
    data: shape({
        menu_group: arrayOf(shape({
            group_title: string,
            display_text: string,
            link_group: arrayOf(shape({
                uid: string,
                _content_type_uid: string,
            })),
            promoted_spots: arrayOf(shape({
                uid: string,
                _content_type_uid: string,
            })),
            linking: shape({
                tracking_event_category: string,
                tracking_event_action: string,
                tracking_event_label: string,
            }),
        })),
        styling: shape({
            fonts: shape({
                department: string,
                category: string,
                subcategory: string,
                subsubcategory: string,
                promoted: string,
                font_sizes: shape({
                    main_level: number,
                    dd_main_level: number,
                    dd_second_level: number,
                    drop_down_third_level: number,
                    dd_promo: number,
                }),
            }),
            colors: shape({
                bg_nav: shape({ color: string }),
                main_link: shape({ color: string }),
                main_link_hover: shape({ color: string }),
                bg_main_link_hover: shape({ color: string }),
                bg_drop_down: shape({ color: string }),
                drop_down_link: shape({ color: string }),
                drop_down_link_hover: shape({ color: string }),
                bg_drop_down_link: shape({ color: string }),
            }),
        }),
    }).isRequired,
    brand: shape({
        domain: string,
    }),
    ariaLabel: string.isRequired,
    trackEvent: func,
    keeperData: object,
};
NavigationMenu_V2.defaultProps = {
    brand: {
        domain: 'harryanddavid',
    },
    trackEvent: () => {},
    keeperData: {},
};

const mapDispatchToProps = (dispatch) => ({
    trackEvent: bindActionCreators(track, dispatch),
});

export default connect(
    null,
    mapDispatchToProps,
)(NavigationMenu_V2);
