/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    arrayOf, bool, shape, string, number, func,
} from 'prop-types';
import { makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import LinkGroup from './LinkGroup';
import PromotedSpots from './PromotedSpot';
import Backdrop from './Backdrop';

const useStyles = makeStyles(() => ({
    dropdownContainer: ({ isDropDownShown }) => ({
        position: 'relative',
        opacity: 0,
        width: 0,
        height: 0,
        left: 0,
        // overflow: 'hidden',
        zIndex: '10',
        transition: `opacity ${isDropDownShown ? '1s ease-in-out' : '.3s'}`,
        transitionDelay: isDropDownShown ? '.2s' : '.4s', // outgoing animation
        '&.show': {
            opacity: 1,
            width: 'auto',
            height: 'auto',
            transition: `opacity ${isDropDownShown ? '.3s ease-in-out' : '.7s'}`, // incoming animation transition
        },
    }),
    dropdownBody: {
        display: 'none',
        justifyContent: 'flex-start',
        width: '100%',
        maxWidth: '1440px',
        height: 'auto',
        margin: '0 auto',
        padding: 'clamp(1rem, 2vw, 2rem)',
        '&.show': {
            display: 'flex',
        },
    },
    closeMenu: {
        display: 'none',
        // set to display on one of two conditions, tablet or short viewports (<768px)
        '@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2)': {
            display: 'none',
            justifyContent: 'flex-end',
            alignItems: 'center',
            fontSize: 'min(1rem, 1.3vw)',
            maxWidth: '1570px',
            margin: '5px 10px 0',
            '&.show': {
                display: 'flex',
            },
        },
    },
}));

const DropdownContent = ({
    groupTitle, closeDropdown, styling, linkgroup, promospots, menuIndex, menuOpen, isDropDownShown,
}) => {
    const isOpen = menuIndex === menuOpen && isDropDownShown;
    const classes = useStyles({ styling, isOpen, isDropDownShown });

    return (
        <>
            <div className={`${classes.dropdownContainer} ${isOpen ? 'show' : ''} dropdown`} data-testid={`dropdown_${menuIndex}`}>
                <div // this is only seen on tablets or screens that are <768px...  hopefully
                    role="button"
                    tabIndex="0"
                    className={`${classes.closeMenu} ${isOpen ? 'show' : ''}`}
                    onKeyPress={closeDropdown}
                    onClick={closeDropdown}
                    onTouchEnd={closeDropdown}
                    data-testid={`closedropdown_${menuIndex}`}
                >
                    <div>Close Menu</div>
                    <CloseIcon />
                </div>

                <ul role="menu" className={`${classes.dropdownBody} ${isOpen ? 'show' : ''}`}>
                    <LinkGroup groupTitle={groupTitle} data={linkgroup} styling={styling} closeDropdown={closeDropdown} />
                    <PromotedSpots groupTitle={groupTitle} promospots={promospots} styling={styling} closeDropdown={closeDropdown} />
                </ul>
                {(styling.show_backdrop && isOpen) && <Backdrop closeDropdown={closeDropdown} />}
            </div>

        </>
    );
};

DropdownContent.defaultProps = {
    linkgroup: [],
    promospots: [],
    menuIndex: 0,
    menuOpen: -1,
};
DropdownContent.propTypes = {
    styling: shape({
        fonts: shape({
            department: string,
            category: string,
            subcategory: string,
            subsubcategory: string,
            promoted: string,
            font_sizes: shape({
                main_level: number,
                dd_main_level: number,
                dd_second_level: number,
                drop_down_third_level: number,
                dd_promo: number,
            }),
        }),
        colors: shape({
            bg_nav: shape({ color: string }),
            main_link: shape({ color: string }),
            main_link_hover: shape({ color: string }),
            bg_main_link_hover: shape({ color: string }),
            bg_drop_down: shape({ color: string }),
            drop_down_link: shape({ color: string }),
            drop_down_link_hover: shape({ color: string }),
            bg_drop_down_link: shape({ color: string }),
        }),
        viewport: shape({
            width: number,
            documentHeight: number,
            height: number,
        }),
    }).isRequired,
    linkgroup: arrayOf(shape({
        uid: string,
        _content_type_uid: string,
    })),
    promospots: arrayOf(shape({
        uid: string,
        _content_type_uid: string,
    })),
    isDropDownShown: bool.isRequired,
    closeDropdown: func.isRequired,
    menuIndex: number,
    menuOpen: number,
    groupTitle: string.isRequired,
};

export default DropdownContent;
