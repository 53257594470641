/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import {
    string, func, shape, bool, arrayOf, number, object,
} from 'prop-types';
import { useSelector } from 'react-redux';
import { useUIDSeed } from 'react-uid';
import parseFont from '../helpers/parseFontFamily';
import openInNewTab from '../../../../../helpers/contentstack/openInNewTab';
import { getSSRDeviceType } from '../../../../../../state/ducks/App/App-Selectors';
import cleanText from '../helpers/cleanText';
import getLinkReplacement from '../helpers/getLinkReplacement';
import CustomIcon from '../../../../ContentTypeComponents/CustomIcons';
import useIsKeeper from '../../../../../helpers/hooks/useIsKeeper';
import { getActiveABTest } from '../../../../../../state/ducks/App/ducks/ABTesting/ABTesting-Selectors';

const hoverStyles = (styling) => {
    if (styling.hover_styles?.main_links === 'button') {
        return (
            {
                display: 'flex',
                background: styling?.colors?.bg_main_link_hover?.color || '#1f1f1b',
                color: styling?.colors?.main_link_hover?.color || '#fff',
            }
        );
    }
    return {
        color: styling?.colors?.main_link_hover?.color || '#fff',
    };
};

const useStyles = makeStyles(() => ({
    topMenuBar: ({
        styling,
    }) => ({
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: styling?.colors?.bg_nav?.color || '#ffffff',
        width: '100%',
        whiteSpace: 'nowrap',
        fontSize: '16px',
        border: `1px solid ${styling?.colors?.main_link?.color || '#000000'}${75}`, // the 75 is an alpha to nerf the 'saturation' a tad
        borderWidth: '1px 0',
        zIndex: '10',

        '&>ul ': { // mainLinkList
            width: '100%',
            maxWidth: '1400px',
            margin: '0 auto',
            padding: 0,
            display: 'flex',
            justifyContent: styling?.main_navigation_alignment || 'space-around',
            listStyle: 'none',
            '@media screen and (min-width: 768px) and (max-width: 1023px)': {
                // if the main nav has too many top-level links for tablet, cms allows you to hide it.
                '& .hideOn_tablet': {
                    display: 'none',
                },
            },

            '@media screen and (max-width: 767px)': {
                '& .hideOn_mobile': { // this is probably not necessary, but I am putting it here anyway
                    display: 'none',
                },
            },
        },
        // Menu Main Button styles and animations
        '&>ul>.mainLink': {
            listStyle: 'none',
            '&>div': {
                display: 'flex',
                alignContent: 'center',
            },
            '&[touch] div[class*="dropdownContainer"]': {
                opacity: '1 !important',
            },
            '&.hover': { //
                boxShadow: styling.hover_styles?.main_links === 'underline' ? `inset 0 -3px ${styling?.colors?.bg_main_link_hover?.color || '#1f1f1b'}` : 'none',
            },
            '&.hover>a>div, &.hover>.static>div, &.noDropDown:hover>.static>div': hoverStyles(styling, 'main'),
            '& .customIcon': {
                marginRight: '.25em',
                fill: 'currentColor',
            },
        },
        '&>ul>li>a, &>ul>li>.static': {
            display: 'flex',
            width: 'auto',
            textAlign: 'center',
            paddingTop: 'clamp(.2em, .5vw, .47em)', // let the minifier one-line this
            paddingBottom: 'clamp(.2em, .5vw, .5em)',
            paddingLeft: 'clamp(0, .3vw, .5em)',
            paddingRight: 'clamp(0, .3vw, .5em)',
            textDecoration: 'none',
            userSelect: 'none',
            cursor: 'pointer',
            '&>div': {
                display: 'flex',
                alignContent: 'center',
                paddingTop: 'clamp(.2em, .5vw, .5em)', // let the minifier one-line this
                paddingBottom: 'clamp(.2em, .5vw, .5em)',
                paddingLeft: 'clamp(.2em, .5vw, .64em)',
                paddingRight: 'clamp(.2em, .5vw, .64em)',
                color: styling?.colors?.main_link?.color || '#1f1f1b',
                textDecorationColor: `${styling?.colors?.main_link_hover?.color} !important` || '#1f1f1b',
                borderRadius: '8px',
                boxSizing: 'border-box',
                fontFamily: parseFont(styling?.fonts?.department),
                fontSize: `min(1.2vw, ${styling.fonts.font_sizes.main_level || '.95'}rem)`,
            },
        },
        '& sup': {
            fontSize: '.5em',
        },
    }),
    noLink: {
        cursor: 'default !important',
    },
    mainAltColor: ({ styling }) => ({
        '& [data-component="linkType"]': {
            color: `${styling?.colors?.main_link_alt?.color} !important` || '#1f1f1b', // the !important is necessary due to the lack of applicable specificity
        },
        '&.hover [data-component="linkType"]': {
            color: `${styling?.colors?.main_link_alt_hover?.color} !important` || '#1f1f1b',
            textDecorationColor: `${styling?.colors?.main_link_alt_hover?.color} !important` || '#1f1f1b',
        },
        '&.hover': {
            boxShadow: `inset 0 -3px ${styling?.colors?.main_link_alt?.color || '#1f1f1b'} !important`,
        },
    }),
    imageLink: {
        display: 'flex',
        '&>div.static': {
            alignSelf: 'center',
            padding: '0 !important',
            '&>img': {
                alignSelf: 'center',
                height: 'clamp(1.5rem, 2vw, 2rem)',
            },
        },
    },
}));

const NavbarContent = ({
    menuGroup, tabletHover, stopHover, closeDropdown,
    applyTabletHover, startHover, ariaLabel, menuOpen, trackEvent, styling, keeperData,
}) => {
    const isKeeper = useIsKeeper();
    const ssrDeviceType = useSelector(getSSRDeviceType);
    const classes = useStyles({ styling, ssrDeviceType });

    const abMenuReplacement = useSelector(getActiveABTest('nav_menu_links'));

    // adds a class that determines if the element should show on certain device widths. Can prevent crowding on tablet, for example
    const hideOn = (tab) => tab.hide_on?.join(' ');
    const noDropDown = (tab) => (!tab.link_group.length ? 'noDropDown' : '');
    const menuItemHover = (item) => (item === menuOpen ? 'hover' : '');
    const seed = useUIDSeed();
    const hasIcon = (icon) => icon?.svg_data && <CustomIcon data={icon} />;

    const hasImageLink = (tab) => tab.image_menu_item?.url?.length && true;
    const linkType = (tab, titleOnly = false) => {
        const title = (tab?.display_text?.length && cleanText(tab?.display_text)) || (tab?.entry_title?.length && tab?.entry_title) || 'Missing Entry Name';
        if (titleOnly) {
            return title?.[0] || title; // title is returning in []
        }
        if (hasImageLink(tab)) {
            return <img className={classes.imageLink} src={tab.image_menu_item.url} alt={title} />;
        }

        return <div data-testid="deptLevelLink">{hasIcon(tab.icon)}{getLinkReplacement(abMenuReplacement, title?.[0] || title)}</div>;
    };

    const gaTrack = (tab) => {
        const linking = tab?.linking || {};
        let origin = '';
        if (typeof window !== 'undefined' && window.location && window.location.origin) {
            origin = window.location.origin;
        }
        trackEvent({
            eventCategory: linking?.tracking_event_category || '',
            eventAction: linking?.tracking_event_action || '',
            eventLabel: getLinkReplacement(abMenuReplacement, linking?.tracking_event_label) || '',
            header_section: 'Global Nav Tab',
            link_url: `${origin}${getLinkReplacement(abMenuReplacement, linkType(tab, true), linking.link.href, false) }`,
            link_text: getLinkReplacement(abMenuReplacement, linking?.link?.title),
            link_parent: 'Drop Down Header',
        });
    };
    const hiddenLinks = keeperData?.hidden_links || [];

    const notHiddenItem = (item) => {
        if (isKeeper && hiddenLinks.includes((item?.group_title || '')?.toLowerCase())) {
            return false;
        }
        return true;
    };

    return (
        <nav className={classes.topMenuBar} aria-label={ariaLabel} data-testid="NavigationMenuV2">
            <ul className="mainLinkList">
                {menuGroup?.map((tab, item) => (
                    !tab.disable_menu && notHiddenItem(tab) && (
                        <li
                            className={`mainLink ${hideOn(tab) || ''}${menuItemHover(item) || ''}${noDropDown(tab) || ''} ${tab.alt_color ? classes.mainAltColor : ''} ${hasImageLink(tab) ? classes.imageLink : ''}`}
                            key={`nav_${seed(item)}`}
                            data-testid={`mainLevel_${item}`}
                            data-displayName={tab?.group_title}
                            touch={tabletHover === `MainLink_${item}` ? 'true' : 'false'} // needs the ternary otherwise the false comes in as bool and spoils unit test
                            onMouseLeave={stopHover} // having this mouse-out here reduces the chance the menu will close when moving off the hit-state diagonally
                        >
                            {tab?.linking?.link?.href?.length
                                ? (
                                    <Link
                                        className="static"
                                        role="button"
                                        to={getLinkReplacement(abMenuReplacement, linkType(tab, true), tab.linking.link.href, false)}
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...openInNewTab(getLinkReplacement(abMenuReplacement, linkType(tab, true), tab.linking.link.href, false))}
                                        onClick={() => {
                                            if (ssrDeviceType !== 'tablet') {
                                                closeDropdown();
                                                // history.push(tab?.linking?.link?.href);
                                                gaTrack(tab);
                                            }
                                        }}
                                        onTouchStart={() => {
                                            // eslint-disable-next-line no-restricted-globals
                                            event.preventDefault();
                                            applyTabletHover(item);
                                            startHover(tab);
                                        }}
                                        onTouchCancel={() => {
                                            // eslint-disable-next-line no-restricted-globals
                                            event.preventDefault();
                                        }}
                                        onMouseUp={() => {
                                            if (ssrDeviceType !== 'tablet') {
                                                stopHover();
                                                closeDropdown();
                                            }
                                        }}
                                        onMouseEnter={() => {
                                            startHover(tab, item);
                                        }}
                                        id={`MainLink_${item}`}
                                    >
                                        {linkType(tab)}
                                    </Link>
                                ) : (
                                    <div
                                        className={`${classes.noLink} static`}
                                        onMouseEnter={() => {
                                            startHover(tab, item);
                                        }}
                                    >
                                        {linkType(tab)}
                                    </div>
                                )}
                        </li>
                    )
                ))}
            </ul>

        </nav>
    );
};

NavbarContent.propTypes = {
    menuGroup: arrayOf(shape({
        group_title: string,
        display_text: string,
        link_group: arrayOf(shape({
            uid: string,
            _content_type_uid: string,
        })),
        promoted_spots: arrayOf(shape({
            uid: string,
            _content_type_uid: string,
        })),
        linking: shape({
            tracking_event_category: string,
            tracking_event_action: string,
            tracking_event_label: string,
        }),
    })).isRequired,
    tabletHover: bool,
    stopHover: func.isRequired,
    closeDropdown: func.isRequired,
    applyTabletHover: func.isRequired,
    startHover: func.isRequired,
    ariaLabel: string.isRequired,
    menuOpen: bool,
    trackEvent: func.isRequired,
    styling: shape({
        fonts: shape({
            department: string,
            category: string,
            subcategory: string,
            subsubcategory: string,
            promoted: string,
            font_sizes: shape({
                main_level: number,
                dd_main_level: number,
                dd_second_level: number,
                drop_down_third_level: number,
                dd_promo: number,
            }),
        }),
        colors: shape({
            bg_nav: shape({ color: string }),
            main_link: shape({ color: string }),
            main_link_hover: shape({ color: string }),
            bg_main_link_hover: shape({ color: string }),
            bg_drop_down: shape({ color: string }),
            drop_down_link: shape({ color: string }),
            drop_down_link_hover: shape({ color: string }),
            bg_drop_down_link: shape({ color: string }),
        }),
        viewport: shape({
            width: number,
            documentHeight: number,
            height: number,
        }),
    }).isRequired,
    keeperData: object,
};

NavbarContent.defaultProps = {
    menuOpen: false,
    tabletHover: false,
    keeperData: {},
};

export default NavbarContent;
