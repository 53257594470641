/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    shape, string, func, number, object,
} from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { useUIDSeed } from 'react-uid';

// tracking
import { useDispatch } from 'react-redux';
import { trackEvent } from '../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';

// helpers
import cleanText from '../helpers/cleanText';
import parseFont from '../helpers/parseFontFamily';
import openInNewTab from '../../../../../helpers/contentstack/openInNewTab';
import ResponsiveImage from '../../../GraphqlCommonComponents/ResponsiveImage/ResponsiveImage';

const useStyles = makeStyles(() => ({
    promotionSpot: ({ styling, imgData }) => ({
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'top',
        width: '100%',
        listStyle: 'none',
        textAlign: 'left',
        '& a': {
            display: 'block',
            color: styling?.colors?.drop_down_link?.color || '#1f1f1b',
            textDecoration: 'none',
            overflow: 'hidden',
            '&:hover': {
                textDecoration: 'underline',
            },
            '& div': {
                whiteSpace: 'normal',
                marginTop: 'clamp(0, 1vw, .7rem)',
                // marginTop: 'min(1px, .7rem)',
            },
            '& .promoTitle': {
                fontFamily: parseFont(styling?.fonts?.promoted),
                fontSize: `clamp(.6rem, .6vw + .3rem, ${styling?.fonts?.font_sizes?.dd_promo || '1rem'})`,
            },
        },
        '&:hover div': { // zoom image if mousing over any part of the spot (UNUSED AT THIS TIME)
            backgroundSize: '110%',
            transition: `all ${imgData?.size === 'small' ? '5s' : '7s'} ease-in-out`,
        },

    }),
    promoImage: ({ imgData }) => ({
        overflow: 'hidden',
        background: `url(${imgData?.url}) no-repeat center center`,
        backgroundSize: '100%',
        transition: 'all .5s ease-in-out',
        height: 'auto',
    }),
    small: {
        width: 'clamp(120px, 95%, 265px)',
    },
    large: {
        width: 'clamp(200px, 95%, 560px)',
    },

}));

const buildPromoSpot = (data, styling, closeDropdown, item, groupTitle = '') => {
    const dispatch = useDispatch();
    const imgDataInit = data?.size === 'small' ? data?.image_small : data?.image_large;
    const imgData = { ...imgDataInit };
    imgData.size = data?.size;
    const classes = useStyles({ styling, imgData });
    const close = () => closeDropdown();
    const seed = useUIDSeed();
    return (
        <li className={classes.promotionSpot}  key={seed(item)}>
            <Link
                to={{ pathname: data?.linking?.link?.href }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...openInNewTab(data?.linking?.link?.href)}
                title={data?.linking?.link?.title}
                onClick={() => {
                    close();
                    let origin = '';
                    if (typeof window !== 'undefined' && window.location && window.location.origin) {
                        origin = window.location.origin;
                    }
                    dispatch(trackEvent({
                        eventCategory: data?.linking?.tracking_event_category || '',
                        eventAction: data?.linking?.tracking_event_action || '',
                        eventLabel: data?.linking?.tracking_event_label || '',
                        header_section: 'Drop Down Banner',
                        link_url: `${origin}${data?.linking?.link?.href}`,
                        link_text: data?.linking?.link?.title,
                        link_parent: groupTitle,
                    }));
                }}
            >
                <ResponsiveImage path={imgData?.url} alt={cleanText(data?.title_text) || imgData?.title || 'Promoted Spot Image'} className={`${classes.promoImage} ${classes[data?.size]}`} params={{}} />
                <div className={`${classes[data?.size]} promoTitle`}>{cleanText(data?.title_text)}</div>
            </Link>

        </li>
    );
};

const PromotedSpot = ({
    promospots, styling, closeDropdown, groupTitle,
}) => {
    const entries = promospots?.[0]?.entries || [];
    if (entries?.length > 0) {
        const promos = entries?.map((promo, item) => !promo?.disable_entry && buildPromoSpot(promo, styling, closeDropdown, item, groupTitle));
        return promos;
    }
    return null;
};

PromotedSpot.propTypes = {
    promospots: object,
    groupTitle: string,
    styling: shape({
        fonts: shape({
            department: string,
            category: string,
            subcategory: string,
            subsubcategory: string,
            promoted: string,
            font_sizes: shape({
                main_level: number,
                dd_main_level: number,
                dd_second_level: number,
                drop_down_third_level: number,
                dd_promo: number,
            }),
        }),
        colors: shape({
            bg_nav: shape({ color: string }),
            main_link: shape({ color: string }),
            main_link_hover: shape({ color: string }),
            bg_main_link_hover: shape({ color: string }),
            bg_drop_down: shape({ color: string }),
            drop_down_link: shape({ color: string }),
            drop_down_link_hover: shape({ color: string }),
            bg_drop_down_link: shape({ color: string }),
        }),
    }).isRequired,
    closeDropdown: func.isRequired,
};

PromotedSpot.defaultProps = {
    promospots: {},
    groupTitle: '',
};

export default PromotedSpot;
